import Rails from '@rails/ujs'; // gives us Rails.fire (see submitRecaptchableForm)

$(document).on('turbolinks:load', function() {

  if ($('body.sign_up_requests.body-new, body.sign_up_requests.body-create, body.contact_requests.body-contact, body.acp_requests, body.fiber_requests, body.return_hardware_requests').length > 0) {
    initializeRecaptcha();
  }

  $('#signUpForm button[type="submit"]').click(function(e) {
    e.preventDefault();
    
    submitRecaptchableForm('signUpForm',
                           'recaptchaTokenField',
                           'signup');
    return false;
  });

  $('#acpForm button[type="submit"]').click(function(e) {
    e.preventDefault();
    
    submitRecaptchableForm('acpForm',
                           'recaptchaTokenField',
                           'acp');
    return false;
  });
 
  $('#contactForm button[type="submit"]').click(function(e) {
    e.preventDefault();

    submitRecaptchableForm('contactForm',
                           'recaptchaTokenField',
                           'contact');
    return false;
  });

  $('#fiberRequestForm button[type="submit"]').click(function(e) {
    e.preventDefault();

    submitRecaptchableForm('fiberRequestForm',
                           'recaptchaTokenField',
                           'landsend');
    return false;
  });

  $('#returnHardwareRequestForm').submit(function(e) {
    e.preventDefault();
    
    submitRecaptchableForm('returnHardwareRequestForm',
                           'recaptchaTokenField',
                           'hardware');
    return false;
  });

});


function initializeRecaptcha() {  
  var script = document.createElement("script");
  script.type = "text/javascript";
  script.src = "https://www.google.com/recaptcha/api.js?render=6LckNawbAAAAAGkAWdHE5VFWcSE0aa3tPahvmlqK";
  document.getElementsByTagName("head")[0].appendChild(script);
  return false;
}


function submitRecaptchableForm(formId, tokenFieldId, action) {
  grecaptcha.ready(function() {
    grecaptcha.execute('6LckNawbAAAAAGkAWdHE5VFWcSE0aa3tPahvmlqK', { action: action }).then(function(token) {
      
      document.getElementById(tokenFieldId).value = token;
      var recaptchaedForm = document.querySelector('#' + formId);

      var isRemote = $(recaptchaedForm).data('remote');
      if (isRemote) {
        Rails.fire(recaptchaedForm, 'submit');
      }
      else {
        recaptchaedForm.submit();
      }
    });
  });
}
